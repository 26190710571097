<template>
<div class="bg-style">
  <div class="login page-content">
    <div class="login-title">
      <img src="../assets/logo_img.png" alt="" srcset="">
    </div>
    <div class="from-wrapper">
      <div class="tab-box">
        <div
          class="tab-item"
          :class="loginstatus ? 'active' : ''"
          @click="changeStatus(1)"
        >
          我是员工
        </div>
        <div
          class="tab-item"
          :class="!loginstatus ? 'active' : ''"
          @click="changeStatus(2)"
        >
          我是游客
        </div>
      </div>
      <div class="input-box" v-if="loginstatus">
        <div class="login-tps">
          账号
        </div>
        <div class="input-innder-box">
            <input
                type="text"
                class="input-item"
                placeholder=""
                v-model="loginId"
                placeholder-style="color:#D2D2D2;"
                />
        </div>
        <div class="login-tps">
          密码
        </div>
        <div class="input-innder-box">
          <input
            :type="pwdtype"
            class="input-item"
            placeholder=""
            v-model="pwd"
            placeholder-style="color:#D2D2D2;"
          />

        </div>
      </div>
      <div class="input-box" v-else>
        <div class="login-tps">
          手机号码
        </div>
        <div class="input-innder-box">
          <input
            type="number"
            class="input-item"
            placeholder=""
            maxlength=""
            v-model="phone"
            placeholder-style="color:#D2D2D2;"
          />
        </div>
        <div class="login-tps">
          验证码
        </div>
        <div class="code-box">
          <input
            type="number"
            maxlength="6"
            class="input-item input-item1"
            placeholder=""
            v-model="verifyCode"
            placeholder-style="color:#D2D2D2;"
          />
          <div class="code-pos" @click="getcode">
            {{text}}
            <!-- <input
              type="text"
              class="code-input"
              disabled="true"
              :value="text"
            /> -->
          </div>
        </div>
      </div>
      <button class="button-box" v-if="loginstatus" @click="sysUserLogin">
        绑定
      </button>
      <button class="button-box" v-else @click="exteriorUserLogin">绑定</button>
    </div>
  </div>
</div>
</template>

<script>
// @ is an alias to /src
import { Toast, Field, Button } from "vant";
import Cookies from "js-cookie";
export default {
  name: "login",
  data() {
    return {
      loginId: "",
      phone: "",
      pwd: "",
      tips: "",
      verifyCode: "",
      code: "",
      loginstatus: true,
      text: "获取验证码",
      checkstatus: "",

      staffId: "",

      showEye: true,
      pwdtype: "password",

      openId:Cookies.get("openid")
    };
  },
  methods: {
    eyeFn(type) {
      if (type == "close") {
        this.showEye = false;
        this.pwdtype = "text";
      } else {
        this.showEye = true;
        this.pwdtype = "password";
      }
    },

    // 改变登录方式
    changeStatus(status) {
      console.info(status);
      if (status == "1") {
        this.loginstatus = true;
      } else {
        this.loginstatus = false;
      }
    },
    // 获取验证码
    async getcode() {
      var reg = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
      if (this.text != "获取验证码") {
        return false;
      }
      if (!reg.test(this.phone)) {
        return Toast("正确输入手机号码");
      }
      console.info(this.phone);

      let params = {
        phone: this.phone,
        openId: this.openId
      };
      Toast.loading();
      await this.$post("/h5/weChat/exteriorUserGetCaptcha", params).then((res) => {
        if (res.code == "0") {
          Toast('已发送至手机')
          this.countdown();
        } else {
          Toast(res.message);
        }
      });
      
     
    },
    // 倒计时
    countdown() {
      this.text = "60s";
      let n = 59;
      let timer = setInterval(() => {
        let time = n--;
        this.text = `${time}s`;

        if (n == 0) {
          clearInterval(timer);
          this.text = "获取验证码";
        }
      }, 1000);
    },

    // 用户绑定
    async sysUserLogin() {
      if (!this.loginId) {
        return Toast("请输入账号");
      }

      if (!this.pwd) {
        return Toast("请输入密码");
      }
      Toast.loading({
        duration: 0,
        forbidClick: true,
      });
      var params = {
        userName: this.loginId,
        passWord: this.pwd,
        openId: this.openId
      };
      await this.$post("/h5/weChat/sysUserLogin", params).then((res) => {
        if (res.code == "0") {
          Toast(res.message);
          Cookies.set("tonken", res.result.token);
          setTimeout(() => {
            this.$router.push({ path: "/" });
          }, 1000);
        } else {
          Toast(res.message);
        }
      });
    },

    // 外部人员的用户绑定
    async exteriorUserLogin() {
      var reg = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
        if (!reg.test(this.phone)) {
        return Toast("正确输入手机号码");
      }
      if (!this.verifyCode) {
        return Toast("请输入验证码");
      }
      Toast.loading({
        duration: 0,
        forbidClick: true,
      });
      
      var params = {
        phone: this.phone,
        captcha: this.verifyCode,
        openId: this.openId
      };
      await this.$post("/h5/weChat/exteriorUserLogin", params).then((res) => {
        if (res.code == "0") {
          Toast(res.message);
          setTimeout(() => {
            this.$router.push({ path: "/" });
          }, 1000);
        } else {
          Toast(res.message);
        }
      });
    },
  },
  // beforeRouteEnter: (to, from, next) => {
  //   next((vm) => {
  //     if (Cookies.get("openid") && Cookies.get("userBind")) {
  //       next();
  //     } else {
  //       next('/');
  //     }
  //   });
  // },
  mounted() {
    // let Height = document.body.clientHeight;//页面载入时获取body高度
    // console.info(Height)
    // window.onresize = function(){
    //     console.log("1")
    //      document.body.style.height= Height+"px";
    // }
  },
  components: {
    [Toast.name]: Toast,
    [Field.name]: Field,
    [Button.name]: Button,
  },
};
</script>

<style lang="scss" scoped>
.eye-box {
  position: relative;
//   border-bottom: 1px solid #dedede;
}
.eye-box img {
  position: absolute;
  width: 24px;
  height: 12px;
  top: 50%;
  right: 18px;
  /* transform: rotateY(-50%); */
  margin-top: -6px;
}
.input-box {
  overflow: hidden;
  margin-top: 20px;
}
.bg-style{
  background-color: #2b4a95;
  min-height: 100vh;
}
/* pages/login/wxlogin/wxlogin.wxss */ /* pages/mine/login/login.wxss */
.page-content {
  min-height: 100%;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  background: #fff url(../assets/invited-bg.png) no-repeat center center;
  background-size: 100% 100%;

  // width: 100%;
  // // height: 100vh;
  // background-size: 100% 100%;
  // position:absolute;
}
.login-title {
  font-size: 30px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  padding: 55px 0 50px;
  img{
    width: 198px;
    height: 60px;
    font-size: 0;
  }
}

.from-wrapper {
  margin: 0 50px;
  padding: 28px 0px 34px 0px;
//   background-color: #fff;

  box-shadow: 0px 7px 4px 1px rgba(73, 87, 238, 0.08);
  border-radius: 20px;
}
.tab-box {
  text-align: center;
}
.tab-item {
  display: inline-block;
  width: 117px;
  padding: 12px 0;
  position: relative;
  font-size: 18px;
  font-weight: 400;
  color: #CEDDFF;
}
.tab-item.active {
  font-weight: bold;
  color: #fff;
}
.tab-item.active::after {
  position: absolute;
  content: "";
  width: 35px;
  height: 5px;
  background: #fff;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.login-tps {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin-top: 25px;
  margin-bottom: 12px;
}
// .login-tps img {
//   width: 16px;
//   height: 16px;
//   margin-right: 10px;
//   vertical-align: text-top;
// }
.input-innder-box{
    overflow: hidden;
    border-radius: 22px;
}
.input-item {
  padding: 13px 0px;
  padding-left: 20px;
  font-size: 16px;
  border: none;
  width: 100%;
  border-radius: 22px;
//   border-bottom: 1px solid #dedede;
}
.eye-box .input-item {
  border: none;
  outline: none;
  width: 100%;
}
.input-item1 {
  width: 140px;
  padding: 13px 0px;
  padding-left: 20px;
  font-size: 16px;
//   border-bottom: 1px solid #dedede;
  /* margin-top: 40rpx; */
}
.code-box {
  position: relative;
}
.code-pos {
  position: absolute;
  width: 105px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  font-size: 14px;
    color: #526EFF;
  background: #fff;
  border-radius: 22px;
  text-align:center;
  top: 0;
  right: 0;
}
.code-input {
  padding: 13px 0px;
  background: transparent;
  border: none;
  outline: none;
}
.input-item:first-child {
  /* margin-bottom: 40rpx; */
  // width: 100%;
}

/* 登录 */
.button-box {
  width: 100%;
  height: 44px;
//   background: #007af5;
  background: linear-gradient(0deg, #526EFF 0%, #6F8EFF 100%);
  border-radius: 20px;

  font-size: 19px;
  font-weight: 500;
  color: #ffffff;
  line-height: 44px;
  margin-top: 50px;
  border: none;
  outline: none;
}
.code-login {
  text-align: center;
  font-size: 14px;
  color: #1f83d5;
}

.wxlogin {
  margin-top: 50px;
  text-align: center;
  background-color: #fff;
}
.wximg {
  width: 33px;
  height: 33px;
  font-size: 0;
  margin-bottom: 5px;
}
.tips {
  font-size: 14px;
  color: #bfbfbf;
  font-weight: 500;
}
::-webkit-input-placeholder {
  color: #d2d2d2;
}

/* Firefox 4-18 */

:-moz-placeholder {
  color: #d2d2d2;
}

/* Firefox 19-50 */

::-moz-placeholder {
  color: #d2d2d2;
}

/* - Internet Explorer 10?C11

   - Internet Explorer Mobile 10-11 */

:-ms-input-placeholder {
  color: #d2d2d2 !important;
}

/* Edge (also supports ::-webkit-input-placeholder) */

::-ms-input-placeholder {
  color: #d2d2d2;
}

/* CSS Working Draft */

::placeholder {
  color: #d2d2d2;
}
</style>
